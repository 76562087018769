import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container} from "react-bootstrap"

export default ({data, location}) => {
  const {page} = data
  return (
    <Layout location={location}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description}
      />
      <Container>
        <div className="bg-white py-3 px-2">
          <h1>{page.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{__html: page.html}} />
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`